:root {
  --rct-color-tree-bg: #f6f8fa;

  --rct-color-focustree-item-selected-bg: #e8eaed;
  --rct-color-focustree-item-selected-text: inherit;

  --rct-color-nonfocustree-item-selected-bg: #e8eaed;
  --rct-color-nonfocustree-item-selected-text: inherit;
  --rct-color-nonfocustree-item-focused-border: #dbdbdb;

  --rct-color-arrow: #9aa5b3;

  --rct-item-height: 28px;
  --rct-arrow-width: 16px;
}

.rct-tree-root {
  font-family: sans-serif;
  background-color: var(--rct-color-tree-bg);
  padding: 4px 0;
}

.rct-tree-item-li {
  font-size: 0.8rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rct-tree-item-title-container {
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.rct-tree-item-button {
  flex-grow: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  border: none;
  width: 100%;
  height: var(--rct-item-height);
  text-align: left;
  color: inherit;
  outline: none;
}

.rct-tree-item-arrow {
  width: var(--rct-arrow-width);
}

.rct-tree-item-arrow svg {
  width: var(--rct-arrow-width);
}

.rct-tree-item-arrow-path {
  fill: var(--rct-color-arrow);
}

.rct-tree-items-container {
  margin: 0;
  padding: 0;
}

.rct-tree-root:not(.rct-tree-root-focus) .rct-tree-item-title-container-selected {
  background-color: var(--rct-color-nonfocustree-item-selected-bg);
  color: var(--rct-color-nonfocustree-item-selected-text);
}
.rct-tree-root.rct-tree-root-focus .rct-tree-item-title-container-selected {
  background-color: var(--rct-color-focustree-item-selected-bg);
  color: var(--rct-color-focustree-item-selected-text);
}
.rct-tree-root.rct-tree-root-focus .rct-tree-item-title-container-focused {
  outline: none;
}
.rct-tree-root:not(.rct-tree-root-focus) .rct-tree-item-title-container-focused {
  outline: none;
  border-color: var(--rct-color-nonfocustree-item-focused-border);
}
