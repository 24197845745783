@import "@wfp/ui/src/globals/scss/styles.scss";
@import "components/Table/table.scss";

html {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fbfcfc;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  #root {
    --main-font-size: 14px;
    --selected-color: #dce9f3ba;
    --background-color: #fbfcfc;
    --header-height: 55px;
  }
}

.wfp--user__title {
  display: none;
}

.react-select__indicators {
  color: blue;
}
.rt-th {
  outline: none;
}

.wfp--secondary-navigation__list {
  padding: 1.75rem 0;
  width: 100%;

  .wfp--tabs {
    margin-bottom: 0;
  }
}

.page_root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wfp--wrapper--width-lg {
  max-width: 100%;
}

.wfp--footer {
  z-index: 0;
}

.error-field {
  .wfp--form-requirement {
    margin-bottom: 10px;
  }
}

.line {
  border-top: 1px solid #e0e3e6;
  width: 100%;
}

.paddingTopSmallest {
  margin-top: 1em;
}

.paddingTopSmall {
  margin-top: 2em;
}

.marginRightSmall {
  margin-inline-end: 1em;
}

.marginLeftSmall {
  margin-inline-start: 1em;
}

.wfp--form-controls {
  margin-left: auto;
}

.formItem {
  margin-bottom: 1.3rem;

  .wfp--label {
    font-size: 1rem;
  }
}

.wfp--form-long .wfp--form-item {
  margin-bottom: 0px;
}

.alignCenterItemsWidth {
  display: flex;
  width: 100%;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.rotate90 {
  transform: rotate(90deg);
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

.nutritionModule {
  background-color: #f5f5f5 !important;
  padding: 1rem 1.25rem !important;
}

.pointer {
  cursor: pointer;
}

.iconPrimary {
  fill: #3077bc;
}

.listStyle {
  list-style-type: none;
  list-style-position: outside;
  padding-top: 1em;
  line-height: 1.75em;
  margin-left: 1em;
}

.dotStyle:before {
  content: "•";
  color: #3077bc;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.filters {
  margin-top: 1.5em;
  margin-right: 2em;
  margin-bottom: 1.5em;
  align-self: flex-end;
}

.wfp--tabs {
  min-height: 0;
}

.tabs {
  margin-left: 0 !important;
}

.wfp--tabs__nav {
  background-color: #fff;
}

.wfp--tab-content {
  margin-top: 0;
  > {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.suboptimal {
  padding-top: 1em;
  padding-bottom: 1em;
}

.rowSelected {
  background-color: var(--selected-color);
}

.wfp--main-navigation__user .wfp--main-navigation__sub--open {
  width: auto;
  min-width: none;
}

// Class made for src/containers/ResultsCommunity/PDFResults. We have visually hidden the component without using "display: none", which wasn't working when trying to download the PDF (the function trying to get the DOM element couldn't find it in the app tree)
// https://gomakethings.com/hidden-content-for-better-a11y/#hiding-the-link
.visuallyHidden {
  border: 0;
  clip-path: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.wfp--tag--custom {
  background-color: #dfe3e6;
}

.wfp--sub-navigation__header {
  background: var(--ui-02);
}

// Custom class for react-cookie-consent button wrapper.
.CookieConsent--button-wrapper {
  margin: 0.5rem 0;

  .wfp--btn + .wfp--btn {
    margin-inline-start: 0.8rem;
  }
}
