/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #85b7da;
  color: white;
  border: 1px solid #85b7da;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #007dbc;
  color: white;
  border: 1px solid #007dbc;
}

.CalendarDay__selected_span:hover {
  background: #007dbc;
  color: white;
  border: 1px solid #007dbc;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #085387;
  color: white;
  border: 1px solid #085387;
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #85b7da;
  color: white;
  border: 1px solid #85b7da;
}

.DateInput_input {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-bottom: none;
}

.DateRangePickerInput_arrow_svg {
  // Marked as important otherwise styling coming from UI kit overrides them
  height: 18px !important;
  width: 18px !important;
}
