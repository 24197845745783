@import "@wfp/ui/src/globals/scss/vars";
@import "@wfp/ui/src/globals/scss/colors";
@import "@wfp/ui/src/globals/scss/typography";
@import "@wfp/ui/src/globals/scss/spacing";
@import "@wfp/ui/src/globals/scss/helper-mixins";
@import "@carbon/import-once/scss/import-once";
@import "@wfp/ui/src/globals/scss/css--reset";

.#{$prefix}--table {
  th,
  td {
    padding: $spacing-xs;
  }

  th {
    border-right: 1px solid var(--ui-04);
    text-align: left;
  }

  thead {
    background: var(--ui-03);
    border-bottom: 2px solid #dfe3e6;
    font-weight: 600;
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--ui-04);
    }
  }
}

.ReactTable {
  @include reset;
  @include font-family;
  font-size: 1rem;
  position: relative;
  width: 100%;

  a {
    border-bottom: none;
  }
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  flex: 1;
  display: flex;
  min-height: 140px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.wfp--pagination {
  background-color: var(--ui-02);
  border: 0;
  .wfp--pagination__left {
    > :nth-child(3) {
      visibility: hidden;
    }
  }
  .wfp--pagination__button--forward {
    border: 0;
    padding: 0;
    margin-right: 20px;
  }
  .wfp--pagination__button--backward {
    border: 0;
    padding: 0;
    margin-left: 20px;
  }
  .wfp--select--inline .wfp--select-input:hover {
    &:hover {
      background: var(--ui-04) !important;
    }
  }
}

// .wfp--pagination above is maintained since it's being used by other tables around the app. Will delete it at the very end of layout rework
.wfp--pagination-new {
  background-color: var(--ui-01);

  // Hides "X of Y items" - still needed?
  .wfp--pagination__left {
    > :nth-child(3) {
      visibility: hidden;
    }
  }
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid var(--ui-04);
  background: var(--ui-02);
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  font-weight: 600;
}

.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
  padding: 0.5rem;
  line-height: 1.2rem;
  position: relative;
  box-shadow: inset 0 0 0 0 transparent;
  border: none;
  &.-sort-asc:after {
    top: 55%;
  }
}

.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  &:after {
    position: absolute;
    top: 50%;
    margin-top: -0.8rem;
    right: 0.6em;
    font-size: 1.3em;
    content: "▾";
    color: $interactive-01;
  }
}

.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  &:after {
    transform: rotate(180deg);
    content: "▾";
    margin-top: -0.5rem;
  }
}

.ReactTable .rt-thead .rt-td.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-td:last-child,
.ReactTable .rt-thead .rt-th:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: var(--text-01);
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: var(--ui-04);
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: var(--ui-04);
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  .rt-tr:hover {
    background: rgb(249, 251, 252);
  }
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: 0;
  }
}

.ReactTable .rt-td,
.ReactTable .rt-th {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding: 7px 0.5em;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;

  &.small-multiline {
    font-size: 0.95rem;
    white-space: pre-wrap;
  }

  &.right {
    flex-direction: row-reverse;
  }

  &.center {
    > span {
      display: block;
      margin: auto;
    }
  }

  &.highlighted {
    background: $interactive-01;
  }
}

.ReactTable .rt-td.-hidden,
.ReactTable .rt-th.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg) scaleY(0.6);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid $interactive-01;
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
}

.ReactTable .rt-tfoot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-spacing-xs .rt-tr {
  padding: $spacing-xs;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  z-index: 0;
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable input:not([type="checkbox"]):not([type="radio"]),
.ReactTable select {
  appearance: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"])::-ms-expand,
.ReactTable select::-ms-expand {
  display: none;
}

.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}

.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px;
}

.ReactTable .select-wrap:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ReactTable .rt-resizing .rt-td,
.ReactTable .rt-resizing .rt-th {
  -webkit-transition: none !important;
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

/* Cell Classes in work */
.#{$prefix}--cell--bold {
  font-weight: 700;
}

.#{$prefix}--cell--large-number {
  font-size: 2.5rem;
}

.#{$prefix}--cell--list {
  line-height: 1.5rem;
}

@include breakpointMax(bp--sm--major) {
  .ReactTable--responsive {
    .rt-thead,
    .rt-tbody {
      min-width: inherit !important;
    }

    .rt-thead {
      display: none;
    }

    .rt-tr {
      display: block;
      display: flex;
      flex-direction: column;
    }

    .rt-th,
    .rt-td {
      width: 100% !important;
      max-width: inherit !important;
    }

    .rt-expandable {
      height: inherit !important;
      width: inherit !important;
    }

    .rt-expander {
      color: $interactive-01;
      margin: 0;

      &::after {
        display: none;
      }
    }
  }

  .ReactTable--responsive__cell__title {
    display: block;
    font-weight: 600;
    margin-top: rem(5px);
    margin-bottom: rem(5px);
    width: 100%;
  }
}

@include breakpoint(bp--sm--major) {
  .ReactTable--responsive__cell__title {
    display: none;
  }

  .rt-expander {
    button {
      display: none;
    }
  }
}

.ReactTable__subcomponent {
  padding: rem(20px);
}

.rt-table,
.rt-tbody {
  overflow: visible !important;
}
